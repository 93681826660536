<template>
  <div>
    <div class="vx-row mb-6 mt-4">
      <div class="vx-col w-1/2">
        <select-operating-unit @data="setOperatingUnit"></select-operating-unit>
        <select-territory
          @data="setTerritory"
          ref="select_territory"
          :operatingUnit="operatingUnits"
        ></select-territory>
        <!-- <select-sales-channel
          @data="setSalesChannel"
          ref="select_sales_channel"
        >
        </select-sales-channel> -->
        <select-supervisor
          @data="setSupervisor"
          ref="select_supervisor"
        ></select-supervisor>
        <select-salesman-subtype
          @data="setSalesmanSubtype"
          ref="select_salesman_subtype"
        >
        </select-salesman-subtype>
        <select-salesman
          @data="setSalesman"
          ref="select_salesman"
          :personal_id="personal_id"
          :territory_id="territory_id"
          :sales_channel_id="sales_channel_id"
        >
        </select-salesman>
        <input-date @data="setDate" ref="input_date"></input-date>
      </div>
    </div>

    <div class="vx-row mt-2 ml-2">
      <vs-button
        class="ml-2"
        color="primary"
        type="border"
        icon-pack="feather"
        icon="icon-plus"
        @click="handleFilter()"
        >Browse</vs-button
      >

      <!-- export -->
      <vs-button
        class="ml-2"
        color="primary"
        type="border"
        icon-pack="feather"
        icon="icon-download"
        @click="handleExport()"
        >Export</vs-button
      >

      <!-- reset -->
      <vs-button
        class="ml-2"
        color="danger"
        type="filled"
        icon-pack="feather"
        icon="icon-refresh-cw"
        @click="handleReset()"
        >Reset</vs-button
      >
    </div>

    <div class="">
      <vs-table
        search
        stripe
        border
        description
        :sst="false"
        :data="table.data"
        :max-items="table.length"
        :total="table.length"
        @change-page="handleChangePage"
      >
        <template slot="thead">
          <vs-th>No</vs-th>
          <vs-th>Sales Office</vs-th>
          <vs-th>Salesman Name</vs-th>
          <vs-th>Call Plan</vs-th>
          <vs-th>Visited (In Route)</vs-th>
          <vs-th>Visited (%)</vs-th>
          <vs-th>Visited (Out Of Route)</vs-th>
          <vs-th>Effective Call (In Route)</vs-th>
          <vs-th>Effective Call (Out Of Route)</vs-th>
          <vs-th>Actual Sales (SFA)</vs-th>
          <vs-th>Actual Collection (SFA)</vs-th>
        </template>
        <template slot="header">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span class="mr-2"
                >{{ this.table.start }} - {{ this.table.end }} of
                {{ this.table.total }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item
                v-for="item in table.limits"
                :key="item"
                @click="handleChangelength(item)"
              >
                <span>{{ item }}</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </template>
        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>{{ table.row_number_start + indextr }}</vs-td>
            <vs-td>{{ tr.territory_name }}</vs-td>
            <vs-td>{{ tr.personal_name }}</vs-td>
            <vs-td>{{ tr.call_plan }}</vs-td>
            <vs-td>{{ tr.visited_in_route }}</vs-td>
            <vs-td>{{ tr.visited_percentage }}</vs-td>
            <vs-td>{{ tr.visited_out_of_route }}</vs-td>
            <vs-td>{{ tr.effective_call_in_route }}</vs-td>
            <vs-td>{{ tr.effective_call_out_of_route }}</vs-td>
            <vs-td>{{ formatNumber(tr.actual_sales) }}</vs-td>
            <vs-td>{{ formatNumber(tr.actual_collection) }}</vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination
        style="padding-top: 5px"
        :total="table.totalPage"
        v-model="setPage"
      />
    </div>
  </div>
</template>

<script>
import SelectOperatingUnit from "./_components/select-operating-unit.vue";
import SelectTerritory from "./_components/select-territory.vue";
import SelectSalesChannel from "./_components/select-sales-channel.vue";
import SelectSupervisor from "./_components/select-supervisor.vue";
import SelectSalesman from "./_components/select-salesman.vue";
import SelectSalesmanSubtype from "./_components/select-salesman-subtype.vue";
import InputDate from "./_components/input-date.vue";
import { currencyFormat, formatNumber } from "@/utils/common";

export default {
  components: {
    SelectOperatingUnit,
    SelectTerritory,
    SelectSalesChannel,
    SelectSupervisor,
    SelectSalesman,
    InputDate,
    SelectSalesmanSubtype,
  },
  data() {
    return {
      table: this.tableDefaultState(),
      date: null,
      salesman: {
        id: null,
        name: null,
      },
      salesman_subtypes: [],
      operatingUnits: [],
      territorries: [],
      salesChannel: {
        id: null,
        code: null,
      },
      supervisors: [],
      personal_id: null,
      territory_id: null,
      sales_channel_id: null,
    };
  },
  methods: {
    formatNumber,
    currencyFormat,
    setDate(date) {
      this.date = date;
    },
    setSalesman(id, name) {
      this.salesman.id = id;
      this.salesman.name = name;
    },
    setSalesmanSubtype(selecteds) {
      this.salesman_subtypes = selecteds.map((item) => {
        return {
          personal_id: item.personal_id,
          work_position_id: item.work_position_id,
          name: item.name,
        };
      });
      this.personal_id = null;
      this.territory_id = null;
    },
    setSupervisor(selecteds) {
      this.supervisors = selecteds.map((item) => {
        return item.personal_id;
      });
    },
    setSalesChannel(id, code) {
      this.salesChannel.id = id;
      this.salesChannel.code = code;
    },
    setOperatingUnit(selecteds) {
      this.operatingUnits = selecteds.map((item) => {
        return {
          id: item.id,
          code: item.code,
        };
      });
    },
    setTerritory(selecteds) {
      this.territorries = selecteds.map((item) => {
        return {
          id: item.territory_id,
          code: item.code,
        };
      });
      this.territory_id = null;
      this.personal_id = null;
    },

    handleReset() {
      // reload
      this.$router.go(0);
    },
    async handleExport() {
      this.table.is_export_excel = true;
      await this.getData();
      this.table.is_export_excel = false;
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      if (this.table.total > 0) {
        this.getData();
      }
    },
    handleChangePage(page) {
      this.table.page = page;
      if (this.table.total > 0) {
        this.getData();
      }
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      if (this.table.total > 0) {
        this.getData();
      }
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      if (this.table.total > 0) {
        this.getData();
      }
    },
    async getData() {
      let salesman_ids = [];
      let territorryIDs = [];

      if (this.$refs.select_salesman.selected != null) {
        salesman_ids = this.$refs.select_salesman.selected.map(
          (item) => item.personal_id
        );
      }

      if (this.territorries.length > 0) {
        territorryIDs = this.territorries.map((item) => item.id);
      } else {
        this.$vs.notify({
          title: "Error",
          text: "Please select territory",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        return;
      }

      if (this.date == null) {
        this.$vs.notify({
          title: "Error",
          text: "Please select date",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        return;
      }

      this.$vs.loading();

      let params = {
        ...this.table,
        sales_ids: salesman_ids,
        date: this.date,
        territory_ids: territorryIDs,
      };
      this.table.is_export_excel = false;

      let results = await this.$http.get(
        `/api/sfa/v1/monitoring-dashboard/report/summary-customer-visit`,
        {
          params: params,
        }
      );

      if (results.code == 200) {
        if (results.data != null) {
          this.table.data = results.data.records;
        }
        this.$vs.notify({
          title: "Success",
          text: results.message,
          color: "success",
        });
        this.$vs.loading.close();
      } else {
        this.table.data = [];
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Failed",
          text: results.message,
          color: "danger",
        });
      }

      this.table.total = results.data.total_record;
      this.table.totalPage = results.data.total_page;
      this.table.totalSearch = results.data.total_record_search;
      this.table.length = results.data.total_record_per_page;
      this.table.row_number_start = results.data.row_number_start;
      this.table.row_number_end = results.data.row_number_end;

      this.setStartEnd();

      this.$vs.loading.close();
    },
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
        is_export_excel: false,
        row_number_start: 0,
        row_number_end: 0,
      };
    },
    async handleFilter() {
      await this.getData();
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
  },
  mounted() {},
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  watch: {
    operatingUnits: function (val) {
      if (val.length == 0) {
        return;
      }
      let ids = val.map((item) => item.id);
      let params = {
        operating_unit_ids: ids,
        length: 0,
      };
      this.$refs.select_territory.getData(params);
    },
    territorries: function (val) {
      if (val.length == 0) {
        return;
      }
      this.$refs.select_salesman.reset();
      this.$refs.select_salesman.setType("territory");
      let params = {
        territory_ids: val.map((item) => item.id),
        length: 0,
      };
      this.$refs.select_salesman.getDataByTerritory(params);

      params = {
        territory_ids: val.map((item) => item.id),
        length: 0,
      };
      this.$refs.select_supervisor.getData(params);
    },
    supervisors: function (val) {
      if (val.length == 0) {
        return;
      }
      this.$refs.select_salesman.reset();
      this.$refs.select_salesman.setType("supervisor");
      let params = {
        sales_spv_ids: val,
        length: 0,
      };
      this.$refs.select_salesman.getDataByTerritoryV2(params);

      this.$refs.select_salesman_subtype.reset();
      params = {
        sales_spv_ids: val,
        length: 0,
      };
      this.$refs.select_salesman_subtype.getData(params);
    },
    salesman_subtypes: function (val) {
      if (val.length == 0) {
        return;
      }

      this.$refs.select_salesman.reset();
      this.$refs.select_salesman.setType("territory");
      let params = {
        sales_spv_ids: this.supervisors.map((item) => item),
        subtype_ids: val.map((item) => item.work_position_id),
        length: 0,
      };
      this.$refs.select_salesman.getDataByTerritoryV2(params);
    },
  },
};
</script>
