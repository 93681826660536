<template>
  <vx-card :title="title">
    <vs-tabs>
      <vs-tab label="Data">
        <div class="tab-text">
          <data-table />
        </div>
      </vs-tab>

      <vs-tab label="Export">
        <div class="tab-text">
          <export />
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>

<script>
import DataTable from "./data.vue";
import Export from "./export.vue";

export default {
  components: {
    DataTable,
    Export,
  },
  data() {
    return {
      title: "Report Summary Customer Visit",
    };
  },
};
</script>
